import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { MainContextProvider } from '../context/storeContext';
import { SetPageTitle } from '../components/global/PageTitles/SetPageTitle';
import { Notification } from '../components/global/Notification/Notification';
import Preloader from '../components/global/preloader/Preloader';
import { LoginPage } from '../pages/auth/login';
import RequireAuth from '../hooks/RequireAuth';
import createPropsApiClient from '../context/apiClient';
import { Layout } from '../components/layout/Layout';
import ProfilePage from '../pages/profile/Profile';
import Dasboard from '../pages/dasboard/Dasboard';
import Managers from '../pages/managers/Managers';
import Base from '../pages/base/Base';
import Brons from '../pages/brons/Brons';
import Distribution from '../pages/distribution/Distribution';
import Deals from '../pages/deals/Deals';
import Traffic from '../pages/traffic/Traffic';
import MenuMobile from '../pages/menu/Menu';
import Conversions from '../pages/conversions/Conversions';
import Spents from '../pages/spents/Spents';
import Finance from '../pages/finance/Finance';

export const AllRoutes = (): JSX.Element => {
	const apiProps = createPropsApiClient();

	return (
		<MainContextProvider api={apiProps}>
			<SetPageTitle />
			<Notification />
			<Preloader />
			<Routes>
				<Route path="/" element={<RequireAuth><LoginPage /></RequireAuth>} />
				<Route path='/' element={<Layout />}>
					<Route path='/profile' element={<RequireAuth><ProfilePage/></RequireAuth>}/>
					<Route path='/dashboard' element={<RequireAuth><Dasboard/></RequireAuth>}/>
					<Route path='/managers' element={<RequireAuth><Managers/></RequireAuth>}/>
					<Route path='/base' element={<RequireAuth><Base/></RequireAuth>}/>
					<Route path='/brons' element={<RequireAuth><Brons/></RequireAuth>}/>
					<Route path='/distribution' element={<RequireAuth><Distribution/></RequireAuth>}/>
					<Route path='/deals' element={<RequireAuth><Deals/></RequireAuth>}/>
					<Route path='/traffic' element={<RequireAuth><Traffic/></RequireAuth>}/>
					<Route path='/cv' element={<RequireAuth><Conversions/></RequireAuth>}/>
					<Route path='/menu' element={<RequireAuth><MenuMobile/></RequireAuth>}/>
					<Route path='/spents' element={<RequireAuth><Spents/></RequireAuth>}/>
					<Route path='/finance' element={<RequireAuth><Finance/></RequireAuth>}/>
				</Route>
			</Routes>
		</MainContextProvider>
	);
};