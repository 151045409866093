/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { useApi } from '../../hooks/useApi';
import styles from './finance.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { formatNumberWithSpaces } from '../../utils/numbers';
import FinanceAccount from '../../components/UI/account/account';
import CreateUpdateAccountPopUP from './popUps/accounts/create/CreateAccount';
import cn from 'classnames';
import CustomCheckbox from '../../components/UI/Checkbox/CustomCheckbox';
import Separator from '../../components/UI/Separator/Separator';
import GetOperationResp from '../../api/finance/dto/Operations/GetOperationResp';
import moment from 'moment';
import TableRow from '../../components/UI/TableRow/TableRow';
import { Button } from '../../components/UI/Button/Button';
import { isToday } from '../../utils/date';
import OperationActionPopUP from './popUps/operations/create/OperationAction';
import { FinanceAccountModel, OperationTypes } from '../../api/finance/dto/FinanceTypes';
import AmountFinance from '../../components/UI/finance/amount/Amount';
import TransactionChartBalance from '../../components/UI/finance/TransactionChartBalance/TransactionChartBalance';
import GetDailyReportResp from '../../api/finance/dto/Reports/GetDailyReportResp';
require('moment/locale/ru');

type OperationsGrouped = {
	date: string;
	dateName: string;
	operations: GetOperationResp[];
}

const Finance: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [accounts, setAccounts] = useState<FinanceAccountModel[]>([]);
	const [totalBalanceRub, setTotalBalanceRub] = useState(0);
	const [totalBalanceUsd, setTotalBalanceUsd] = useState(0);

	const [operations, setOperations] = useState<GetOperationResp[]>([]);
	const [operationsGrouped, setOperationsGrouped] = useState<OperationsGrouped[]>([]);
	const [selectedOperations, setSelectedOperations] = useState<GetOperationResp[]>([]);

	const [totalIncomeRub, setTotalIncomeRub] = useState(0);
	const [totalExpenseRub, setTotalExpenseRub] = useState(0);
	const [totalSaldoRub, setTotalSaldoRub] = useState(0);

	const [totalPlanedIncomeRub, setTotalPlanedIncomeRub] = useState(0);
	const [totalPlanedExpenseRub, setTotalPlanedExpenseRub] = useState(0);
	const [totalPlanedSaldoRub, setTotalPlanedSaldoRub] = useState(0);

	const [totalIncomeUsd, setTotalIncomeUsd] = useState(0);
	const [totalExpenseUsd, setTotalExpenseUsd] = useState(0);
	const [totalSaldoUsd, setTotalSaldoUsd] = useState(0);

	const [totalPlanedIncomeUsd, setTotalPlanedIncomeUsd] = useState(0);
	const [totalPlanedExpenseUsd, setTotalPlanedExpenseUsd] = useState(0);
	const [totalPlanedSaldoUsd, setTotalPlanedSaldoUsd] = useState(0);

	const [showRowIncome, setShowRowIncome] = useState(false);
	const [showRowExpense, setShowRowExpense] = useState(false);
	const [showRowSaldo, setShowRowSaldo] = useState(false);

	const [summaries, setSummaries] = useState<GetDailyReportResp[]>([]);

	const getBalanceReport = async () => {
		try {
			store.notificationsStore.showPreloader();

			const {body} = await api.finance.GetDailyReport(
				{
					startDate: moment(store.dateStore.startDate).format('YYYY-MM-DD'),
					endDate: moment(store.dateStore.endDate).format('YYYY-MM-DD'),
					currency: 'USD'
				}
			);

			if (body) {
				setSummaries(body);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}


	useEffect(() => {
		const operationsForCalc = selectedOperations.length > 0 ? selectedOperations : operations;
	
		const initialState = {
			incomeRub: 0,
			expenseRub: 0,
			incomePlanedRub: 0,
			expensePlanedRub: 0,
			incomeUsd: 0,
			expenseUsd: 0,
			incomePlanedUsd: 0,
			expensePlanedUsd: 0,
		};
	
		const totals = operationsForCalc.reduce((acc, operation) => {
			const { amount_to, amount_from, to_account, from_account, is_planned } = operation || {};
	
			if (amount_to && to_account?.currency === 'RUB') {
				is_planned ? acc.incomePlanedRub += amount_to : acc.incomeRub += amount_to;
			}
			if (amount_from && from_account?.currency === 'RUB') {
				is_planned ? acc.expensePlanedRub -= amount_from : acc.expenseRub -= amount_from;
			}
			if (amount_to && to_account?.currency === 'USD') {
				is_planned ? acc.incomePlanedUsd += amount_to : acc.incomeUsd += amount_to;
			}
			if (amount_from && from_account?.currency === 'USD') {
				is_planned ? acc.expensePlanedUsd -= amount_from : acc.expenseUsd -= amount_from;
			}
	
			return acc;
		}, initialState);
	
		// Обновление состояний
		const {
			incomeRub,
			expenseRub,
			incomePlanedRub,
			expensePlanedRub,
			incomeUsd,
			expenseUsd,
			incomePlanedUsd,
			expensePlanedUsd
		} = totals;
	
		setTotalIncomeRub(incomeRub);
		setTotalExpenseRub(expenseRub);
		setTotalSaldoRub(incomeRub + expenseRub);
	
		setTotalIncomeUsd(incomeUsd);
		setTotalExpenseUsd(expenseUsd);
		setTotalSaldoUsd(incomeUsd + expenseUsd);
	
		setTotalPlanedIncomeRub(incomePlanedRub + incomeRub);
		setTotalPlanedExpenseRub(expensePlanedRub + expenseRub);
		setTotalPlanedSaldoRub(incomePlanedRub + expensePlanedRub + incomeRub + expenseRub);
	
		setTotalPlanedIncomeUsd(incomePlanedUsd + incomeUsd);
		setTotalPlanedExpenseUsd(expensePlanedUsd + expenseUsd);
		setTotalPlanedSaldoUsd(incomePlanedUsd + expensePlanedUsd + incomeUsd + expenseUsd);
	
		const haveDifIncomeRub = incomeRub !== (incomePlanedRub + incomeRub);
		const haveDifIncomeUsd = incomeUsd !== (incomePlanedUsd + incomeUsd);
		setShowRowIncome(haveDifIncomeRub || haveDifIncomeUsd);
	
		const haveDifExpenseRub = expenseRub !== (expensePlanedRub + expenseRub);
		const haveDifExpenseUsd = expenseUsd !== (expensePlanedUsd + expenseUsd);
		setShowRowExpense(haveDifExpenseRub || haveDifExpenseUsd);
	
		const haveDifSaldoRub = (incomeRub + expenseRub) !== (incomePlanedRub + expensePlanedRub + incomeRub + expenseRub);
		const haveDifSaldoUsd = (incomeUsd + expenseUsd) !== (incomePlanedUsd + expensePlanedUsd + incomeUsd + expenseUsd);
		setShowRowSaldo(haveDifSaldoRub || haveDifSaldoUsd);
	}, [selectedOperations, operations]);
	

	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(50);

	useEffect(() => {
		getAccounts();
		getBalanceReport();
		getOperations(page, rowsPerPage);
	}, [store.dateStore.startDate, store.dateStore.endDate]);

	const getAccounts = async () => {
		try {
			store.notificationsStore.showPreloader();

			const {body} = await api.finance.GetAccounts();

			if (body) {
				setAccounts(body);

				const balanceRub = body.reduce((acc: number, account: FinanceAccountModel) => {
					if (account.currency === 'RUB') {
						acc += account.balance;
					}
					return acc;
				}, 0);

				const balanceUsd = body.reduce((acc: number, account: FinanceAccountModel) => {
					if (account.currency === 'USD') {
						acc += account.balance;
					}
					return acc;
				}, 0);

				setTotalBalanceRub(balanceRub);
				setTotalBalanceUsd(balanceUsd);
			}

		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const getOperations = async (skip: number, take: number) => {
		try {
			store.notificationsStore.showPreloader();
	
			const { body, total: totalNew } = await api.finance.GetOperations({
				startDate: moment(store.dateStore.startDate).format('YYYY-MM-DD'),
				endDate: moment(store.dateStore.endDate).format('YYYY-MM-DD'),
				skip: skip,
				take: take
			});

			if (!body || body?.length === 0) {
				setTotal(0);
				setOperations([]);
				setOperationsGrouped([]);
				return;
			}
	
			if (body) {
				if (totalNew) {
					setTotal(totalNew);
				}
	
				setOperations(body.sort((a, b) => {
					const dateA = new Date(a.date);
					const dateB = new Date(b.date);
					return dateB.getTime() - dateA.getTime();
				}
				));
			}
	
		} catch (error) {
			store.notificationsStore.setError('Ошибка при загрузке операций');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	};

	//Группировка операций по дате
	useEffect(() => {
		if (!operations || operations.length === 0) {
			setOperationsGrouped([]);
			return;
		}
	
		const operationsGroupedGet: OperationsGrouped[] = [];
	
		operations.forEach((operation: GetOperationResp) => {
			const formattedDate = moment(operation.date).format('YYYY-MM-DD');
			const groupIndex = operationsGroupedGet.findIndex((group: OperationsGrouped) => group.date === formattedDate);
	
			if (groupIndex === -1) {
				operationsGroupedGet.push({
					date: formattedDate,
					dateName: formatDate(operation.date),
					operations: [operation]
				});
			} else {
				operationsGroupedGet[groupIndex].operations.push(operation);
			}
		});
	
		operationsGroupedGet.sort((a, b) => {
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);
			return dateB.getTime() - dateA.getTime(); // Сортировка по убыванию дат
		});
	
		setOperationsGrouped(operationsGroupedGet);
	
	}, [operations]); // Зависимость от изменения массива operations
	

	const deleteOperationFromList = (operationId: string) => {
		setOperations(operations.filter(operation => operation.id !== operationId));
		setTotal(total - 1);
		getAccounts();
		getBalanceReport();
	}

	const addNewOperationToList = (operation: GetOperationResp) => {

		const newOperations = [...operations, operation];
	
		setOperations(newOperations.sort((a, b) => {
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);
			return dateB.getTime() - dateA.getTime();
		}));

		setTotal(prevTotal => prevTotal + 1);
		getAccounts();
		getBalanceReport();
	};
	

	const updateOperationInList = (operation: GetOperationResp) => {
		setOperations(prevOperations => 
			prevOperations.map(op => (op.id === operation.id ? operation : op))
		);
		getAccounts();
		getBalanceReport();
	};

	function formatDate(dateString: string) {
		const date = moment(dateString, 'YYYY-MM-DD');
		return date.format('D MMMM');
	}

	const [showPopupCreateAccount, setShowPopupCreateAccount] = useState(false);

	const togglePopupCreateAccount = () => {
		setShowPopupCreateAccount(!showPopupCreateAccount);
	}

	const [checkedAllOperations, setCheckedAllOperations] = useState(false);

	const [showPopupEditOperation, setShowPopupEditOperation] = useState(false);

	const togglePopupEditOperation = () => {
		setShowPopupEditOperation(!showPopupEditOperation);
	}

	const [operationForPopup, setOperationForPopup] = useState<GetOperationResp | null>(null);
	const [operationTypeForPopup, setOperationTypeForPopup] = useState<OperationTypes>('expense');

	const openPopupEditOperation = (operation: GetOperationResp) => {
		setOperationTypeForPopup(operation.operation_type);
		setOperationForPopup(operation);
		togglePopupEditOperation();
	}

	const openCreateOperationPopup = (operationType: OperationTypes) => {
		setOperationForPopup(null);
		setOperationTypeForPopup(operationType);
		togglePopupEditOperation();
	}

	const containerTransactionChartBalanceRef = useRef<HTMLDivElement>(null); // Ссылка на контейнер
	const [clientWidth, setClientWidth] = useState<number>(0);

	useEffect(() => {
		const updateWidth = () => {
			if (containerTransactionChartBalanceRef.current) {
				setClientWidth(containerTransactionChartBalanceRef.current.offsetWidth); // Устанавливаем ширину контейнера
			}
		};

		updateWidth(); // Рассчитываем ширину при монтировании компонента

		// Добавляем слушатель на изменение размеров окна
		window.addEventListener('resize', updateWidth);

		// Удаляем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []);

	return (
		<>
			{showPopupCreateAccount && <CreateUpdateAccountPopUP  onClose={togglePopupCreateAccount} updateAccounts={getAccounts} account={null}/>}
			{
				showPopupEditOperation && 
			<OperationActionPopUP 
				addNewOperationToList={addNewOperationToList} 
				deleteOperationList={deleteOperationFromList} 
				updateOperationList={updateOperationInList} 
				onClose={togglePopupEditOperation} 
				operation={operationForPopup} 
				operationTypeSet={operationTypeForPopup}
			/>
			}
			<div className={styles.container}>

				<div className={styles.firstBlock}>
					<h2>Финансы</h2>
					<div className={styles.verticalLine} style={{height: 40}}/>
					<p className={styles.balanceNumber}>{formatNumberWithSpaces(totalBalanceRub)} ₽</p>
					<p className={styles.balanceNumber}>{formatNumberWithSpaces(totalBalanceUsd)} $</p>
				</div>

				<div className={styles.horizontalLine}/>

				<div className={styles.accountsContainer}>

					{accounts && accounts.map((account: FinanceAccountModel) => (
						<FinanceAccount key={account.id} account={account} updateAccounts={getAccounts}/>
					))
					}

					<div className={styles.addAccount} onClick={() => setShowPopupCreateAccount(true)}>
					+
					</div>

				</div>

				<div className={styles.horizontalLine}/>

				<div className={styles.buttonsActionsContainer}>
					<Button className={styles.buttonsActions} appearance='auth' onClick={() => openCreateOperationPopup('expense')}>— РАСХОД</Button>
					<Button className={styles.buttonsActions} appearance='auth' onClick={() => openCreateOperationPopup('transfer')}>→ ПЕРЕВОД</Button>
					<Button className={styles.buttonsActions} appearance='auth' onClick={() => openCreateOperationPopup('income')}>+ ПРИХОД</Button>
				</div>

				<div className={styles.TransactionChartBalanceContainer} ref={containerTransactionChartBalanceRef}>

					<TransactionChartBalance
						summaries={summaries}
						transactionDate={{from: store.dateStore.startDate, to: store.dateStore.endDate}}
						currencySymbol='$'
						clientWidth={clientWidth}
					/>
				
				</div>

				{ total > 0 &&

				<div className={styles.transactionEditableTable}>

					<div className={styles.transactionTable}>

						<div className={styles.tableContent}>

							<div className={styles.tableHead}>

								<div className={styles.tableRow}>
									<div className={cn(styles.tableCellCheckbox)}>
										<CustomCheckbox checked={checkedAllOperations} onChange={(e) => setCheckedAllOperations(e)}/>
									</div>
									<div className={cn(styles.tableCellDate)}>Дата</div>
									<div className={cn(styles.tableCellSumm)}>Сумма</div>
									<div className={cn(styles.tableCellDetails)}>Детали</div>
									<div className={cn(styles.tableCellContractor)}>Контрагент</div>
									<div className={cn(styles.tableCellRequisite)}>Реквизиты</div>
								</div>

							</div>

							<div className={styles.tableBody}>

								{operationsGrouped && operationsGrouped.map((group: OperationsGrouped, i) => (
									<div key={group.date + 'oper'}>
										<Separator text={group.dateName} key={group.date} colored={isToday(group.date)}/>

										{group.operations.map((operation: GetOperationResp) => (
											<TableRow
												openPopup={openPopupEditOperation}
												updateOperation={updateOperationInList}
												key={operation.id}
												operation={operation}
												isSelected={selectedOperations.includes(operation)}
												onCheckboxChange={(checked: boolean) => {
													if (checked) {
														setSelectedOperations([...selectedOperations, operation])
													} else {
														setSelectedOperations(selectedOperations.filter((item) => item.id !== operation.id))
													}
												}}
											/>
										))}

										{i === operationsGrouped.length - 1 && 
										<div className={styles.rowBlank}/>
										}

									</div>	
								))}

								{operations.length < total &&
								<div 
									className={styles.cellShowMore}
									onClick={() => {
										getOperations((page + 1) * rowsPerPage, rowsPerPage);
										setPage(page + 1);
									}}
								>
									<button className={styles.buttonShowMore}>
										<span className={styles.buttonShowMoreContent}>
											<span>Показать еще</span>
										</span>
									</button>
								</div>
								}

							</div>

						</div>



						<div className={styles.tableFoot}>
							<div className={styles.tableRowScrollable}>
								<div className={styles.tableCellCheckbox}/>

								<div className={styles.tableFootColum1}>
									<div className={styles.tableFootText}>Приход</div>
									{showRowIncome  && <div className={styles.tableFootEmpty}/> }
									<div className={styles.tableFootEmptyEnd}/>
									<div className={styles.tableFootText}>Расход</div>
									{showRowExpense  && <div className={styles.tableFootEmpty}/> }
									<div className={styles.tableFootEmptyEnd}/>
									<div className={styles.tableFootText}><b>Сальдо</b></div>
									{showRowSaldo && <div className={styles.tableFootEmpty}/> }
									<div className={styles.tableFootEmptyEnd}/>
								</div>

								<div className={styles.tableFootColum2}>

									<AmountFinance amount={totalIncomeRub}currency='₽'/>

									{totalIncomeRub !== totalPlanedIncomeRub && 
									<AmountFinance amount={totalPlanedIncomeRub} currency='₽' secondary />
									}

									{showRowIncome  && totalIncomeRub === totalPlanedIncomeRub && 
										<div className={styles.tableFootEmpty}/>
									}

									<div className={styles.tableFootEmptyEnd}/>

									<AmountFinance amount={totalExpenseRub} currency='₽'/>

									{totalExpenseRub !== totalPlanedExpenseRub && 
									<AmountFinance amount={totalPlanedExpenseRub} currency='₽' secondary />
									}
									
									{showRowExpense && totalExpenseRub === totalPlanedExpenseRub && 
										<div className={styles.tableFootEmpty}/>
									}

									<div className={styles.tableFootEmptyEnd}/>

									<AmountFinance amount={totalSaldoRub} currency='₽' />

									{totalSaldoRub !== totalPlanedSaldoRub && 
									<AmountFinance amount={totalPlanedSaldoRub} currency='₽' secondary />
									}
									
									{showRowSaldo && totalSaldoRub === totalPlanedSaldoRub && 
										<div className={styles.tableFootEmpty}/>
									}

									<div className={styles.tableFootEmptyEnd}/>

								</div>

								<div className={styles.tableFootColum3}>

									<AmountFinance amount={totalIncomeUsd} currency='$' />

									{totalIncomeUsd !== totalPlanedIncomeUsd &&
									<AmountFinance amount={totalPlanedIncomeUsd} currency='$' secondary/>
									}
									
									{showRowIncome  && totalIncomeUsd === totalPlanedIncomeUsd &&
										<div className={styles.tableFootEmpty}/>
									}
									
									<div className={styles.tableFootEmptyEnd}/>

									<AmountFinance amount={totalExpenseUsd} currency='$' />

									{totalExpenseUsd !== totalPlanedExpenseUsd && <AmountFinance amount={totalPlanedExpenseUsd} currency='$'secondary/> }

									{showRowExpense && totalExpenseUsd === totalPlanedExpenseUsd && <div className={styles.tableFootEmpty}/>}
									
									<div className={styles.tableFootEmptyEnd}/>

									<AmountFinance amount={totalSaldoUsd} currency='$' />

									{totalSaldoUsd !== totalPlanedSaldoUsd && 
									<AmountFinance amount={totalPlanedSaldoUsd} currency='$' secondary />
									}

									{showRowSaldo && totalSaldoUsd === totalPlanedSaldoUsd &&
										<div className={styles.tableFootEmpty}/>
									}

									<div className={styles.tableFootEmptyEnd}/>

								</div>

							</div>
						</div>

					</div>

				</div>

				}

				{ total === 0 &&  <div className={styles.emptyResultTable}>В выбранном диапазоне дат нет операций =(</div> }

			</div>

		</>
	);
});

export default Finance;