/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EnvEnum {
	dev = 'Development',
	prod = 'Production'
}

export const env: EnvEnum = process.env.NODE_ENV == 'development' ? EnvEnum.dev : EnvEnum.prod;

const AppSettings = {
	auth: {
		access: {
			name: 'accessToken',
			durationDays: 24 / 1
		},
		refresh: {
			name: 'refreshToken',
			durationDays: 30
		}
	},
	api: {
		// url: 'http://localhost:3010',
		url: 'https://tcb.chmerev.com',
	},
	income_stages: ['d6ZMGwE320-l2MaPw4kUzg'],
	in_work_stages: [
		'ndwysnoeM0KRPTzgJpd4Ng',
		'YK7zsn5KEUm55Gqhf8YlEA',
		'Si2OxME99k6rRW9QXEYbcg',
		'zlpMFPgBiEODvi99wyhOSw',
		'PaY4d-32y0-IIdWH3BZERQ',
		'uwjcLgAb-0mrsS1FGUDISQ'
	],
	realized_stages: [
		'ofE8eo04HkucTlHTSoXiIw'
	],
	unrealized_stages: ['aoPypKs9g0KiabFGwkWKnQ'],
	bronIds: [
		'INgDeb2sQUmnfAKBkDoYsQ',
		'TrMdyOXYcU63qThXDWdE5Q',
		'O5FJPrch9EWAULwNqFhnFQ',
		'11beKPyH_kmBmVkFnMvQeg',
		'rmYMGiRnpUySSEBmSkwVaQ'
	],
	bronIdsSsd: [
		'_Dgmoq2MKEGo8ifQox6j9w'
	],
}

export { AppSettings }
