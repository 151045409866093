/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { scaleTime, scaleLinear } from 'd3-scale';
import { area as areaFunc, curveStepAfter } from 'd3-shape';
import dayjs from 'dayjs';
import { addDate, dateToYMD, roundDate } from '../../../../utils/date';
import { formatNumberWithSpaces, roundNum, zipNum } from '../../../../utils/numbers';
import './TransactionChartBalance.css';
import cn from 'classnames';
import 'dayjs/locale/ru'; 
import TinyLoader from '../tinyLoader/TinyLoader';
dayjs.locale('ru');

interface Summary {
  date: string;
  type: string; // 'regular' or 'planned'
	// balance: number;
	// incoming: number;
	// outcoming: number;
  base_balance: number;
  base_incoming: number;
  base_outcoming: number;
}

interface TransactionChartBalanceProps {
  summaries: Summary[];
  transactionDate: { from: string; to: string };
  currencySymbol?: string;
  clientWidth: number;
}

interface PlanSummariesByDate {
	[key: string]: {
			balance: number;
			income: number;
			outcome: number;
			summaries: Summary[];
	};
}

interface FactItem {
  date: Date;
  balance: number;
}

interface PlanFactItem {
	date: Date;
	balance: number;
	income: number;
	outcome: number;
}

interface Dates {
	date: number;
	caption: string | number;
	weekday: boolean;
	x: number;
}

interface NegativeDates {
	x: number;
	width: number;
}

const TransactionChartBalance: React.FC<TransactionChartBalanceProps> = ({
	summaries,
	transactionDate,
	currencySymbol,
	clientWidth,
}) => {
	
	const [highlight, setHighlight] = useState(false);
	const [highlightDate, setHighlightDate] = useState(Date.now());
	const [clientHeight] = useState(300);
	const [oneDay] = useState(24 * 60 * 60 * 1000);
	const [padding] = useState({ top: 80, bottom: 50 });

	const [dateTo, setDateTo] = useState(Date.now());
	const [dateFrom, setDateFrom] = useState(Date.now());

	const [intervalType, setIntervalType] = useState(0);

	const [minValue, setMinValue] = useState(0);
	const [maxValue, setMaxValue] = useState(0);

	const [plan, setPlan] = useState<PlanFactItem[]>([]);
	const [fact, setFact] = useState<PlanFactItem[]>([]);

	const [plannedSummaries, setPlannedSummaries] = useState<Summary[]>([]);
	const [regularSummaries, setRegularSummaries] = useState<Summary[]>([]);

	const [dates, setDates] = useState<Dates[]>([]);
	const [negativeDates, setNegativeDates] = useState<NegativeDates[]>([]);

	const [months, setMonths] = useState<number[]>([]);

	const [changedYear, setChangedYear] = useState(false);

	const [factYMD, setFactYMD] = useState<{ [key: string]: number }>({});
	const [incomeYMD, setIncomeYMD] = useState<{ [key: string]: number }>({});
	const [outcomeYMD, setOutcomeYMD] = useState<{ [key: string]: number }>({});
	const [dateText, setDateText] = useState('');

	const [balanceIn, setBalanceIn] = useState(0);
	const [balanceOut, setBalanceOut] = useState(0);

	const [showPreloader, setShowPreloader] = useState(false);

	useEffect(() => {
		//Показать прелоадер на 1 секунду
		setShowPreloader(true);
		setTimeout(() => {
			setShowPreloader(false);
		}, 1500);
	}, [transactionDate]);


	useEffect(() => {
		setBalanceIn(fact[0]?.balance ? fact[0].balance : 0);
		setBalanceOut(fact[fact.length - 1]?.balance ? fact[fact.length - 1].balance : 0);
	}, [fact]);

	useEffect(() => {
		const fromDate = dayjs(dateFrom);
		const toDate = dayjs(dateTo);

		const itsSameDate = fromDate.isSame(toDate, 'day');

		setDateText(itsSameDate ? `за ${fromDate.format('D MMMM')}` : `C ${fromDate.format('D MMMM')} по ${toDate.format('D MMMM')}`);
	}, [dateFrom, dateTo]);

	useEffect(() => {
		setFactYMD(
			fact.reduce((result: { [key: string]: number }, value) => {
				result[dateToYMD(value.date)] = value.balance;
				return result;
			}, {})
		);

		setIncomeYMD(
			fact.reduce((result: { [key: string]: number }, value) => {
				result[dateToYMD(value.date)] = value.income;
				return result;
			}, {})
		);

		setOutcomeYMD(
			fact.reduce((result: { [key: string]: number }, value) => {
				result[dateToYMD(value.date)] = value.outcome;
				return result;
			}, {})
		);
	}, [fact]);

	useEffect(() => {
		const from = dayjs(dateFrom).year();
		const to = dayjs(dateTo).year();

		setChangedYear(from !== to);
	}, [dateFrom, dateTo]);


	const highlightFrom = () => {
		return Math.round(x()(highlightDate));
	}

	const highlightWidth = () => {
		return Math.round(x()(dayjs(highlightDate).add(1, 'day').toDate())) - highlightFrom();
	}
	
	const highlightDateText = () => {
		return dayjs(highlightDate).format(changedYear ? 'D MMMM YYYY' : 'D MMMM');
	}
	
	const highlightBalance = () => {
		const ymd = dayjs(highlightDate).format('YYYYMMDD');
		
		return formatNumberWithSpaces(Math.round(factYMD[ymd])).replace('-', '–') + ' ' + currencySymbol;
	}
	
	const highlightIncome = () => {
		const ymd = dayjs(highlightDate).format('YYYYMMDD');
		const value = Math.abs(Math.round(incomeYMD[ymd]));

		return formatNumberWithSpaces(value) + ' ' + currencySymbol;
	}
	
	const highlightOutcome = () => {
		const ymd = dayjs(highlightDate).format('YYYYMMDD');
		const value = Math.abs(Math.round(outcomeYMD[ymd]));

		return '–' + formatNumberWithSpaces(value) + ' ' + currencySymbol;
	}

	// Months
	useEffect(() => {
		const months = () => {
			let date = roundDate(dateFrom, 'month');
			const result = [];
	
			while (date <= dateTo) {
				result.push(Math.round(x()(date)));
	
				date = addDate(date, 1, 'month');
			}
	
			return result;
		}

		setMonths(months());
	}, [dateTo, dateFrom]);

	const todayX = () => {
		return Math.round(x()(roundDate(Date.now() + oneDay, 'date'))) - 1;
	}

	// NegativeDates
	useEffect(() => {
		if (fact) {
			const negativeDates = () => {
				return fact.filter((item) => item.balance < 0).map((item) => {
					const itemDateInMillis = new Date(item.date).getTime(); // Конвертация даты в миллисекунды
					return {
						x: x()(item.date),
						width: x()(itemDateInMillis + oneDay) - x()(itemDateInMillis),
					};
				});
			};

			setNegativeDates(negativeDates());
		}
	}, [fact]);

	// Dates
	useEffect(() => {
		const dates = () => {
			let date = dateFrom;
			const result: Dates[] = [];
	
			if ((dateTo - dateFrom) / oneDay > 45) {
				date = roundDate(date, 'month');
	
				while (date <= dateTo) {
					result.push({
						date: date,
						caption: dayjs(date).format('MMMM'),
						weekday: false,
						x: x()(date) + (x()(date + oneDay * 30) - x()(date)) / 2,
					});
	
					date = addDate(date, 1, 'month');
				}
			} else {
				while (date <= dateTo) {
					const weekDay = new Date(date).getDay();
	
					result.push({
						date: date,
						caption: new Date(date).getDate(),
						weekday: weekDay == 0 || weekDay == 6,
						x: x()(date) + (x()(date + oneDay) - x()(date)) / 2,
					});
	
					date += oneDay;
				}
			}
	
			return result;
		}

		setDates(dates());
	},[dateFrom, dateTo]);

	// plannedSummaries и regularSummaries
	useEffect(() => {
		if (summaries) {
			setPlannedSummaries(summaries.filter((summary) => summary.type === 'planned'));
			setRegularSummaries(summaries.filter((summary) => summary.type === 'regular'));
		}
	}, [JSON.stringify(summaries)]);

	// Даты from и to
	useEffect(() => {
		const from = new Date(Date.parse(transactionDate.from) - oneDay);

		setDateFrom(roundDate(new Date(from.getFullYear(), from.getMonth(), from.getDate()).getTime(), 'date'));
		setDateTo(roundDate(Date.parse(transactionDate.to) + oneDay, 'date'));
	}, [transactionDate]);

	// IntervalType
	useEffect(() => {
		const now = Date.now();

		if (now < dateFrom) {
			setIntervalType(+1);
		}

		if (now > dateTo) {
			setIntervalType(-1);
		}

		setIntervalType(0);
	}, [dateFrom, dateTo]);

	//Минимальное и максимальное значение
	useEffect(() => {
		setMinValue(Math.min(
			fact.reduce((min, summary) => {
				return Math.min(summary.balance, min);
			}, 0),
			plan.reduce((min, summary) => {
				return Math.min(summary.balance, min);
			}, 0)
		))

		setMaxValue(Math.max(
			fact.reduce((max, summary) => {
				return Math.max(summary.balance, max);
			}, 0),
			plan.reduce((max, summary) => {
				return Math.max(summary.balance, max);
			}, 0)
		))
	}, [plan, fact]);

	//Факт
	useEffect(() => {
		const fact = () => {
			let date = dayjs(dateFrom);
			const dateToGet = dayjs(dateTo);
			const now = dayjs();
		
			const result: { date: Date; balance: number; income: number; outcome: number }[] = [];
	
			let balance = 0;
			let income = 0;
			let outcome = 0;
		
			const getPlanSummariesByDate = planSummariesByDate();
			const getFactSummariesByDate = factSummariesByDate();
		
			while (date.isSame(dateToGet) || date.isBefore(dateToGet)) {
				income = 0;
				outcome = 0;
		
				const collection = date.isAfter(now) ? getPlanSummariesByDate : getFactSummariesByDate;
	
				const hash = date.format('YYYYMMDD');
		
				if (date.isSame(dateFrom, 'day')) {
					const firstBalanceDate = date.add(1, 'day').format('YYYYMMDD');
		
					if (collection[firstBalanceDate]) {
						balance = collection[firstBalanceDate].balance;
						income = collection[firstBalanceDate].income;
						outcome = collection[firstBalanceDate].outcome;
		
						balance = roundNum(balance - income + outcome, 2);
					}
				}
		
				if (date.isAfter(dayjs(), 'day')) {
					if (getPlanSummariesByDate[hash]) {
						income = getPlanSummariesByDate[hash].income;
						outcome = getPlanSummariesByDate[hash].outcome;
		
						balance = roundNum(balance + income - outcome, 2);
					}
				} else if (collection[hash]) {
					income = collection[hash].income;
					outcome = collection[hash].outcome;
		
					balance = roundNum(balance + income - outcome, 2);
				}
		
				result.push({
					date: date.toDate(),
					balance,
					income,
					outcome,
				});
		
				date = date.add(1, 'day');
			}
		
			return result;
	
		};

		setFact(fact());
	}, [transactionDate]);

	//План
	useEffect(() => {
		let date = dayjs(dateFrom);
		const getDateTo = dayjs(dateTo);
	
		const result = [];
		let balance = 0;
		let income = 0;
		let outcome = 0;
	
		while (date.isSame(getDateTo) || date.isBefore(getDateTo)) {
			income = 0;
			outcome = 0;
	
			const getPlanSummariesByDate = planSummariesByDate(); 
			const collection = planSummariesByDate();
			const hash = date.format('YYYYMMDD');
	
			// Если дата это предыдущий день начала интервала, то мы можем узнать его баланс
			// только вычислив. Так как мы его не запрашиваем с сервера.
			if (date.isSame(dateFrom, 'day')) {
				const firstBalanceDate = date.add(1, 'day').format('YYYYMMDD');
	
				if (collection[firstBalanceDate]) {
					balance = collection[firstBalanceDate].balance;
					income = collection[firstBalanceDate].income;
					outcome = collection[firstBalanceDate].outcome;
	
					balance = roundNum(balance - income + outcome, 2);
				}
			}
	
			if (date.isAfter(dayjs(), 'day')) {
				if (getPlanSummariesByDate[hash]) {
					income = getPlanSummariesByDate[hash].income;
					outcome = getPlanSummariesByDate[hash].outcome;
	
					balance = roundNum(balance + income - outcome, 2);
				}
			} else if (collection[hash]) {
				income = collection[hash].income;
				outcome = collection[hash].outcome;
	
				balance = roundNum(balance + income - outcome, 2);
			}
	
			result.push({
				date: date.toDate(),
				balance,
				income,
				outcome,
			});
	
			date = date.add(1, 'day');
		}
	

		setPlan(result);
	}, [transactionDate]);

	const x = () => {
		return scaleTime()
			.domain([dateFrom, dateTo])
			.range([0, clientWidth]);
	}

	const y = () => {
		return scaleLinear()
			.domain([maxValue, minValue])
			.range([padding.top, clientHeight - padding.bottom]);
	}

	const mousemove = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		requestAnimationFrame(() => {
			const date = x().invert(event.nativeEvent.offsetX);
			let convertedDate = dayjs(date).startOf('day').toDate().getTime();
	
			if (dateFrom > convertedDate) {
				convertedDate = dateFrom;
			}
	
			if (dateTo < convertedDate) {
				convertedDate = dateTo;
			}
	
			setHighlightDate(convertedDate);
		});
	};

	const round = (value: number) =>  Math.round(value);

	const planSummariesByDate = () => {
		return plannedSummaries.reduce((result: PlanSummariesByDate, summary) => {
			const date = dateToYMD(summary.date);

			if (result[date]) {
				result[date].balance += summary.base_balance;
				result[date].income += summary.base_incoming;
				result[date].outcome += summary.base_outcoming;
				result[date].summaries.push(summary);
			} else {
				result[date] = {
					balance: summary.base_balance,
					income: summary.base_incoming,
					outcome: summary.base_outcoming,
					summaries: [summary],
				};
			}

			return result;
		}, {});
	}

	const factSummariesByDate = () => {
		return regularSummaries.reduce((result: PlanSummariesByDate, summary) => {
			const date = dateToYMD(summary.date);

			if (result[date]) {
				result[date].balance += summary.base_balance;
				result[date].income += summary.base_incoming;
				result[date].outcome += summary.base_outcoming;
				result[date].summaries.push(summary);
			} else {
				result[date] = {
					balance: summary.base_balance,
					income: summary.base_incoming,
					outcome: summary.base_outcoming,
					summaries: [summary],
				};
			}

			return result;
		}, {});
	}

	const factAreaPositive = () => {
		const area = areaFunc<FactItem>();

		// https://bl.ocks.org/d3noob/ced1b9b18bd8192d2c898884033b5529
		area.curve(curveStepAfter);
		area.x((data: FactItem) => Math.round(x()(data?.date)) );
		area.y((data: FactItem) => Math.round(y()(data?.balance)));

		area.y1(clientHeight);

		return area(fact);
	}

	const factAreaNegative = () => {
		const area = areaFunc<FactItem>();

		// https://bl.ocks.org/d3noob/ced1b9b18bd8192d2c898884033b5529
		area.curve(curveStepAfter);
		area.x((data: FactItem) => Math.round(x()(data?.date)));
		area.y((data: FactItem) => Math.round(y()(data?.balance)));

		area.y1(0);

		return area(fact);
	}

	const planPath = () => {
		const area = areaFunc<FactItem>();

		// https://bl.ocks.org/d3noob/ced1b9b18bd8192d2c898884033b5529
		area.curve(curveStepAfter);
		area.x((data: FactItem) => Math.round(x()(data?.date)));
		area.y((data: FactItem) => Math.round(y()(data?.balance)));

		return area(plan);
	}

	const factPath = () => {
		const area = areaFunc<FactItem>();

		// https://bl.ocks.org/d3noob/ced1b9b18bd8192d2c898884033b5529
		area.curve(curveStepAfter);
		area.x((data: FactItem) => Math.round(x()(data?.date)));
		area.y((data: FactItem) => Math.round(y()(data?.balance)));

		return area(fact);
	}

	return (
		<div className="TransactionChartBalance">

			{summaries && summaries.length > 0 && !showPreloader &&
				<svg
					width="100%"
					height={clientHeight} 
					viewBox={`0 0 ${clientWidth} ${clientHeight}`}
					preserveAspectRatio="xMidYMid meet"
					onMouseMove={mousemove}
					onMouseEnter={() => setHighlight(true)}
					onMouseLeave={() => setHighlight(false)}
				>
			
					<clipPath id="clip-negative">
						<rect 
							x={0} 
							y={round(y()(0)) + 1}
							width={clientWidth} 
							height={clientHeight - round(y()(0))}
						></rect>
					</clipPath>

					{todayX() > 0 &&
						<clipPath id="clip-positive-fact">
							<rect 
								x="0" 
								y="0" 
								width={todayX()} 
								height={round(y()(0)) + 1}></rect>
						</clipPath>
					}
			
					{clientWidth - todayX() > 0 &&
						<clipPath id="clip-positive-plan">
							<rect 
								x={todayX()} 
								y="0" 
								width={clientWidth - todayX()}
								height={round(y()(0)) + 1}></rect>
						</clipPath>
					}
			
					{negativeDates.map((date, index) => (
						<rect
							key={index} // Обязательно для динамически создаваемых элементов
							className="TransactionChartBalance__negative-balance"
							x={date.x}
							y={clientHeight - 15}
							width={date.width}
							height={2}
						/>
					))}

					{intervalType <= 0 && 
						<path 
							className="TransactionChartBalance__fact-area-positive" 
							d={factAreaPositive() || ''}
							clipPath="url(#clip-positive-fact)">
						</path>
					}

					{intervalType >= 0 && 
						<path 
							className="TransactionChartBalance__plan-area-positive" 
							d={factAreaPositive() || ''}
							clipPath="url(#clip-positive-plan)">
						</path>
					}
			
					<path 
						className="TransactionChartBalance__area-negative" 
						d={factAreaNegative() || ''}
						clipPath="url(#clip-negative)">
					</path>

					<path 
						className="TransactionChartBalance__plan-path" 
						d={planPath() || ''}>
					</path>

					{intervalType <= 0 && 
						<path 
							className="TransactionChartBalance__fact-path-positive" 
							d={factPath() || ''}
							clipPath="url(#clip-positive-fact)">
						</path>
					}

					{intervalType >= 0 && 
						<path 
							className="TransactionChartBalance__plan-area-positive" 
							d={factPath() || ''}
							clipPath="url(#clip-positive-plan)">
						</path>
					}
			
					<path 
						className="TransactionChartBalance__path-negative-negative" 
						d={factPath() || ''}
						clipPath="url(#clip-negative)">
					</path>

					<rect
						className={cn('TransactionChartBalance__highlight', {['__visible']: highlight})}
						x={highlightFrom()} // Вычисление координаты X для подсветки
						y={0} // Начальная координата Y
						width={highlightWidth()} // Вычисление ширины для подсветки
						height={clientHeight} // Используем высоту контейнера
					/>
			
					{dates.map((date, index) => (
						<text
							key={index}
							className={cn('TransactionChartBalance__date', {['__weekday']: date.weekday})}
							textAnchor="middle"
							x={date.x}
							y={clientHeight - 10}
						>
							{date.caption}
						</text>
					))}

					{intervalType == 0 &&
				<line
					className="TransactionChartBalance__today"
					x1={todayX()}
					y1="5"
					x2={todayX()}
					y2={clientHeight}></line>
					}
			
					{intervalType == 0 &&
				<circle
					className="TransactionChartBalance__today-pin" 
					cx={todayX() - 0.5} 
					cy="9"
					r="5"></circle>
					}
			
					{months.map((x, index) => (
						<line
							key={index}
							className="TransactionChartBalance__month"
							x1={x}
							y1="0"
							x2={x}
							y2={clientHeight}
							stroke="black"  // Добавляем атрибут stroke для видимости линии
						/>
					))}

					{highlight &&
			<>
				<text 
					className="TransactionChartBalance__highlight-date __date-cursor" 
					textAnchor="end"
					x={Math.max(highlightFrom() - 5, 120)}
					y="30">{ highlightDateText() }
				</text>

				<text
					className="TransactionChartBalance__highlight-balance"
					textAnchor="end"
					x={Math.max(highlightFrom() - 5, 120)}
					y="48" v-html="highlightBalance">{ highlightBalance() }
				</text>

				<text
					className="TransactionChartBalance__highlight-income"
					textAnchor="end"
					x={Math.max(highlightFrom() - 5, 120)}
					y="62" v-html="highlightIncome">{ highlightIncome() }
				</text>

				<text
					className="TransactionChartBalance__highlight-outcome"
					textAnchor="end"
					x={Math.max(highlightFrom() - 5, 120)}
					y="74" v-html="highlightOutcome">{ highlightOutcome() }
				</text>

			</>
					}
			
					{!highlight &&
				<text
					className="TransactionChartBalance__highlight-date"
					x="28" 
					y="36">{ dateText }</text>
					}
			
					<text 
						className={cn('TransactionChartBalance__balance', {['__negative']: balanceIn < 0})}
						x="5"
						y={y()(balanceIn) + (balanceIn > 0 ? -7 : +15)}>{ zipNum(balanceIn) }
					</text>

					<text 
						className={cn('TransactionChartBalance__balance', {['__negative']: balanceOut < 0})}
						textAnchor="end"
						x={clientWidth - 5}
						y={y()(balanceOut) + (balanceOut > 0 ? -7 : +15)}>{ zipNum(balanceOut) }
					</text>
			
				</svg>
			}
			
			{showPreloader && 
			<>
			
				<svg 
					width="100%"
					height={clientHeight} 
					viewBox={`0 0 ${clientWidth} ${clientHeight}`}
					preserveAspectRatio="xMidYMid meet">
					<text 
						className="TransactionChartBalance__highlight-date" 
						x="28" 
						y="36">{ dateText }</text>
				</svg>

				<TinyLoader green className="TransactionChartBalance__loader"/>
			</>

			}

		</div>
	);
}

export default TransactionChartBalance;
