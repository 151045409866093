import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { ReactComponent as Exit } from './ico/exit.svg';
import { ReactComponent as AutotrackingIco } from './ico/autotracking.svg';
import Avatar from '../../UI/Avatar/Avatar';
import { useStores } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { baseAccessRoles, bronsAccessRoles, conversionsAccessRoles, dasboardAccessRoles, dealsAccessRoles, distributionAccessRoles, financeAccessRoles, opAccessRoles, spentsAccessRoles, trafficAccessRoles } from '../../../utils/accessRoles';
import ProtectedLink from '../../UI/ProtectedLink/ProtectedLink';

interface SidebarProps {
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = observer((props) => {
	const navigate = useNavigate();
	const {userStore, authStore} = useStores();

	const paths = {
		dashboard: '/dashboard',
		profile: '/profile',
		managers: '/managers',
		base: '/base',
		brons: '/brons',
		distribution: '/distribution',
		deals: '/deals',
		traffic: '/traffic',
		conversions: '/cv',
		spents: '/spents',
		finance: '/finance',
	};

	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');

	useEffect(() => {
		if (userStore.user?.name && userStore.user?.email) {
			setName(userStore?.user?.name.length >= 15 ? `${userStore?.user?.name.slice(0, 15)}...` : userStore.user.name)
			setEmail(userStore?.user?.email?.length >= 19 ? `${userStore.user.email.slice(0, 18)}...` : userStore.user.email)
		}

	}, [userStore.user]);

	

	const exit = async () => {
		await authStore.logout();
		navigate('/');
	};

	// const setActiveLink = (isActive: boolean) => isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	return (
		<div {...props}>

			<div className={styles.menu}>

				<ProtectedLink to={paths.dashboard} roles={dasboardAccessRoles} userRole={userStore?.user?.role as string} label="Дашборд" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.managers} roles={opAccessRoles} userRole={userStore?.user?.role as string} label="Отдел продаж" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.base} roles={baseAccessRoles} userRole={userStore?.user?.role as string} label="База" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.brons} roles={bronsAccessRoles} userRole={userStore?.user?.role as string} label="Брони" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.traffic} roles={trafficAccessRoles} userRole={userStore?.user?.role as string} label="Трафик" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.deals} roles={dealsAccessRoles} userRole={userStore?.user?.role as string} label="Лиды" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.conversions} roles={conversionsAccessRoles} userRole={userStore?.user?.role as string} label="Конверсии" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.distribution} roles={distributionAccessRoles} userRole={userStore?.user?.role as string} label="Распределение" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.spents} roles={spentsAccessRoles} userRole={userStore?.user?.role as string} label="Расходы" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.finance} roles={financeAccessRoles} userRole={userStore?.user?.role as string} label="Финансы" Icon={AutotrackingIco} />


			</div>

			<div className={styles.menuDown}>

				<Link to='/profile' className={styles.profile}>
					<Avatar size='48px' setting={true}/>
					<div>
						<p className={styles.name}>{name}</p>
						<p className={styles.email}>{email}</p>
					</div>
				</Link>

				<div className={styles.exit} onClick={exit}>
					<Exit/>
					<p>Выход</p>
				</div>

			</div>
		</div>
	);
});