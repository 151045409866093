export type allRoles = 'user' | 'admin' | 'rop' | 'technician' | 'marketer'| 'smm' | 'mop' | 'assistant';

export const dasboardAccessRoles = ['admin', 'assistant'];
export const opAccessRoles = ['admin', 'rop', 'technician'];
export const baseAccessRoles = ['admin'];
export const bronsAccessRoles = ['admin'];
export const trafficAccessRoles = ['admin', 'smm'];
export const dealsAccessRoles = ['admin', 'rop'];
export const conversionsAccessRoles = ['admin', 'rop'];
export const distributionAccessRoles = ['admin', 'rop', 'technician'];
export const spentsAccessRoles = ['admin', 'marketer', 'smm'];
export const financeAccessRoles= ['admin'];

// export const adminAccessRoles = ['admin'];
// export const managersStatsAccessRoles = ['admin', 'rop'];
// export const technicianAccessRoles = ['admin', 'technician', 'rop'];
// export const marketingAccessRoles = ['admin', 'technician', 'marketer', 'smm'];
// export const trafficRoles = ['admin', 'smm']

// export const allAccessRoles = [
// 	{id: 'nameSelect', name: 'Выберите роль пользователя'},
// 	{id: 'user', name: 'Пользователь'},
// 	{id: 'admin', name: 'Админ'},
// 	{id: 'manager', name: 'Менеджер'},
// 	{id: 'mentor', name: 'Куратор'},
// 	{id: 'copywriter', name: 'Копирайтер'},
// ];