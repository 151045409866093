/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './menu.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { baseAccessRoles, bronsAccessRoles, conversionsAccessRoles, dasboardAccessRoles, dealsAccessRoles, distributionAccessRoles, financeAccessRoles, opAccessRoles, spentsAccessRoles, trafficAccessRoles } from '../../utils/accessRoles';
import cn from 'classnames';
import { ReactComponent as AutotrackingIco } from '../../components/layout/Sidebar/ico/autotracking.svg';

const MenuMobile: React.FC = observer(() => {
	
	const {userStore} = useStores();

	const paths = {
		dashboard: '/dashboard',
		profile: '/profile',
		managers: '/managers',
		base: '/base',
		brons: '/brons',
		distribution: '/distribution',
		deals: '/deals',
		traffic: '/traffic',
		conversions: '/cv',
		spents: '/spents',
		finance: '/finance',
	};


	const setActiveLink = (isActive: boolean) => isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	return (
		<div>

			<div className={styles.menu}>

				{ dasboardAccessRoles.includes(userStore?.user?.role as string) &&

				<NavLink to={paths.dashboard} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Дашборд</p>
				</NavLink>
				
				}
				
				{ opAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.managers} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Отдел продаж</p>
				</NavLink>
				
				}
				
				{ baseAccessRoles.includes(userStore?.user?.role as string) &&

				<NavLink to={paths.base} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>База</p>
				</NavLink>
				
				}
				
				{ bronsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.brons} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Брони</p>
				</NavLink>
				
				}

				{ trafficAccessRoles.includes(userStore?.user?.role as string) && 

					<NavLink to={paths.traffic} className={({isActive}) => setActiveLink(isActive)}>
						<div className={styles.line}/>
						<AutotrackingIco/>
						<p>Трафик</p>
					</NavLink>

				}

				{ dealsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.deals} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Лиды</p>
				</NavLink>

				}

				{ conversionsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.conversions} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Конверсии</p>
				</NavLink>

				}
				
				{ distributionAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.distribution} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Распределение</p>
				</NavLink>
				
				}

				{ spentsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.spents} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Расходы</p>
				</NavLink>

				}

				{ financeAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.finance} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Финансы</p>
				</NavLink>

				}

			</div>

		</div>
	);
});

export default MenuMobile;